import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useAnalytics, useAnalyticsContext } from "@contexts/Analytics";
import useEvents from "@hooks/useEvents";
import useTemplateData from "@hooks/useTemplateData";
import store from "@store/index";
import { removeSpecialCharsAndNumbers } from "@utils/formatting";
import { blobToImage } from "@utils/index";
import { Logger } from "@utils/logging";
import { mapperLanguageToSDKFormat } from "@utils/string";

export const documentSides = {
  front: "front",
  back: "back",
  both: "both",
};

let documentDetector,
  _result = {},
  _currentSide = documentSides.front;

const { DocumentDetector } = window["@combateafraude/document-detector"];

export default function useDocumentDetector() {
  const { i18n } = useTranslation();
  const { templateData, sdkToken } = useTemplateData();
  const { analyticsTrackingId } = useAnalyticsContext();
  const { logAnalyticsEventInfo, analyticsEvents } = useAnalytics();
  const { emitEvent } = useEvents();
  const eventListeners = useRef([]);

  const { metadata } = store.user;

  const documentStore = store.variables.document;
  const {
    documentIssuedCountry,
    selectedDocumentFileDD,
    selectedDocumentType,
    selectedSendDocumentType,
    isDoubleSided,
  } = documentStore;

  const isInternationalFlow = documentIssuedCountry && documentIssuedCountry !== "BR";

  const addEventListener = (type, listener) => {
    documentDetector.addEventListener(type, listener);
    eventListeners.current.push({ type, listener });
  };

  const removeEventListeners = () => {
    eventListeners.current.forEach(({ type, listener }) => {
      documentDetector.removeEventListener(type, listener);
    });
    eventListeners.current = [];
  };

  const ddStep = useMemo(
    () =>
      templateData?.template?.steps.find((step) => {
        const stepName = removeSpecialCharsAndNumbers(step.name);
        return stepName === "DD";
      }),
    [templateData],
  );

  const sdkOptions = {
    ...ddStep?.sdkOptions?.initialize,
    analytics: {
      trackingId: analyticsTrackingId,
      enabled: true,
    },
    token: sdkToken,
    language: mapperLanguageToSDKFormat(i18n?.language),
    environment: process.env.REACT_APP_SDK_ENV,
    environmentSettings: {
      disableVisibilityChangeSecurity: true,
    },
    uploadSettings: {
      appearenceSettings: {
        backgroundColor: templateData?.template?.colors?.secondary,
      },
    },
    textSettings: { title: "", ...ddStep?.sdkOptions?.initialize?.textSettings },
  };

  if (ddStep?.sdkOptions?.playAudioDescription) {
    sdkOptions.playAudioDescription = ddStep.sdkOptions.playAudioDescription;
  }

  const documentDetectorInitialize = async () => {
    if (!documentDetector?.getIsInitialized()) {
      const startTime = performance.now();
      await documentDetector.initialize();
      const endTime = performance.now();
      logAnalyticsEventInfo(analyticsEvents.DD_INITIALIZE_DURATION, {
        duration: endTime - startTime,
        userAgentData: metadata?.userAgentData,
      });
    }
  };

  const initialize = async (customOptions = undefined) => {
    if (!_currentSide) {
      _result = {};
      _currentSide = documentSides.front;
      documentStore.setDocumentUrlFront("");
      documentStore.setDocumentUrlBack("");
    }

    if (isOnTemplate()) {
      Logger.console("DD initialize", {
        ddStep,
        options: {
          ...sdkOptions,
          ...customOptions,
        },
      });

      const startTime = performance.now();
      documentDetector = new DocumentDetector({
        ...sdkOptions,
        ...customOptions,
        documentDetectionType: isInternationalFlow ? 1 : 0,
      });
      const endTime = performance.now();
      logAnalyticsEventInfo(analyticsEvents.DD_BUILD_DURATION, {
        duration: endTime - startTime,
        userAgentData: metadata?.userAgentData,
      });
    }

    if (!documentDetector?.getIsInitialized()) {
      const permission = await documentDetector.initPermissions();
      const supported = documentDetector.isSupported();

      if (permission && supported) {
        await documentDetectorInitialize();
      }

      return permission && supported;
    }

    return true;
  };
  const setCurrentSideToFront = () => {
    _currentSide = documentSides.front;
  };

  const reset = async () => {
    try {
      if (documentDetector?.getIsInitialized()) {
        await documentDetector.close();
        await documentDetector.dispose();
      }
    } catch (error) {
      Logger.error("DD reset", error);
    }
  };

  const setDocumentUrl = (imageUrl) => {
    if (_currentSide === documentSides.both || _currentSide === documentSides.front) {
      documentStore.setDocumentUrlFront(imageUrl);
    } else {
      documentStore.setDocumentUrlBack(imageUrl);
    }
  };

  const handleSdkResultNational = async (sdkResult, options) => {
    const detectedDocumentSide = sdkResult?.detectedDocument?.side?.toLowerCase();
    const isValidDetectedSide =
      selectedDocumentType &&
      selectedDocumentType?.toUpperCase() !== "ANY" &&
      Object.values(documentSides).includes(detectedDocumentSide);

    /**
     * Considera o side detectado pelo SDK apenas se um tipo de documento conhecido for selecionado na etapa "Tipo de documento",
     * pois esse tipo será enviado ao SDK e atualmente ele só valida o side (se é frente, verso ou both) se for de um tipo de documento conhecido.
     */
    if (isValidDetectedSide) {
      _result[detectedDocumentSide] = sdkResult;
    } else {
      _result[_currentSide] = sdkResult;
    }

    Logger.console("DD capture results (national)", {
      _currentSide,
      _result,
      captured: _result[_currentSide],
    });
    logAnalyticsEventInfo(analyticsEvents.DD_CLOSE, { side: _currentSide, result: _result });

    if (_result[_currentSide]?.image?.url) {
      setDocumentUrl(_result[_currentSide].image?.url);
    } else if (_result[detectedDocumentSide]?.image?.url) {
      setDocumentUrl(_result[detectedDocumentSide].image?.url);
    }

    await options.onGetResults(_currentSide, _result);
  };

  const handleSdkResultInternational = async (sdkResult, options) => {
    if (sdkResult.international.imagesResult && sdkResult.international.imagesResult.length > 0) {
      if (!isDoubleSided) {
        const frontImageResult = sdkResult.international.imagesResult.find(
          (x) => x.documentSide === documentSides.front,
        );
        _result[documentSides.both] = frontImageResult;
      } else {
        const frontImageResult = sdkResult.international.imagesResult.find(
          (x) => x.documentSide === documentSides.front,
        );
        _result[documentSides.front] = frontImageResult;
        documentStore.setDocumentUrlFront(_result[documentSides.front].imageUrl);

        const backImageResult = sdkResult.international.imagesResult.find((x) => x.documentSide === documentSides.back);
        _result[documentSides.back] = backImageResult;
        documentStore.setDocumentUrlBack(_result[documentSides.back].imageUrl);
      }

      Logger.console("DD capture results (international)", {
        _currentSide,
        _result,
        captured: _result,
      });
      logAnalyticsEventInfo(analyticsEvents.DD_CLOSE, { result: _result });

      /**
       * Important: use date-fns format patterns!
       */
      documentStore.setDocumentDateFormat("dd/MM/yyyy"); // TODO: use value returned from sdk when the backend starts returning "documentDateFormat"

      if (sdkResult?.international.textFieldsData) {
        documentStore.setTextFieldsData(sdkResult?.international.textFieldsData);
      }
    } else {
      const message = `International Result -  imagesResult not found`;
      logAnalyticsEventInfo(analyticsEvents.DD_ERROR, message);
    }

    await options.onGetResults(_currentSide, _result);
  };

  const captureHandler = async (options) => {
    removeEventListeners();
    if (isBothSideCapture()) {
      _currentSide = documentSides.both;
    }

    Logger.console("DD capture", {
      metadata: {
        selectedDocumentType,
        selectedSendDocumentType,
        selectedDocumentFileDD,
        documentIssuedCountry,
        isDoubleSided,
        _currentSide,
        _result,
      },
      initialized: documentDetector?.getIsInitialized(),
      isOnTemplate: isOnTemplate(),
      sdkOptions: {
        ddStep: ddStep?.sdkOptions,
        options: options?.sdkOptions,
        sdkOptions,
      },
    });

    try {
      documentDetectorInitialize();

      const ddSdkDispatchedEvents = [
        "capture_invalid",
        "capture_failed",
        "back_capture_started",
        "front_capture_started",
      ];
      ddSdkDispatchedEvents.forEach((type) =>
        addEventListener(type, (e) => {
          if (e?.type === "back_capture_started" || e?.type === "front_capture_started") {
            options?.onCaptureStarted?.(e.type);
          } else if (e?.type === "capture_invalid" || e?.type === "capture_failed") {
            options?.onCaptureInvalid?.(e.type);
          }

          return emitEvent({ code: `SDK_${type}`.toUpperCase(), detail: e?.detail || {} });
        }),
      );

      await options.onInitialize();

      if (isOnTemplate()) {
        logAnalyticsEventInfo(analyticsEvents.DD_START, {
          side: _currentSide,
          type: selectedDocumentType,
          sendType: selectedSendDocumentType,
          result: _result,
        });

        const cleanedSelectedDocumentFileDD = selectedDocumentFileDD.trim().toLowerCase();

        const imageFileType =
          cleanedSelectedDocumentFileDD === "uploadpdf"
            ? "PDF"
            : cleanedSelectedDocumentFileDD === "attachedpicture"
            ? "IMAGE"
            : "";

        const selectCapture = async (captureStages, captureOptions) => {
          const getSelectedDocumentType = () => {
            const selectedDocumentTypeLowerCase = selectedDocumentType?.toLowerCase();

            if (selectedDocumentTypeLowerCase.includes("passport")) {
              return `${selectedDocumentTypeLowerCase}`;
            }

            if (_currentSide === documentSides.both) {
              return `${selectedDocumentTypeLowerCase}_full`;
            }

            return `${selectedDocumentTypeLowerCase}_${_currentSide}`;
          };

          if (isInternationalFlow) {
            return await documentDetector.intlCapture(captureStages, {
              ...captureOptions,
              isDoubleSided: isDoubleSided && _currentSide !== documentSides.both,
            });
          }
          return await documentDetector.capture(captureStages, {
            ...captureOptions,
            expectedDocument: `${getSelectedDocumentType()}`,
          });
        };

        const sdkResult = await selectCapture(
          [
            {
              attempts: 0,
              duration: 0,
              mode:
                selectedSendDocumentType === "upload"
                  ? selectedSendDocumentType
                  : ddStep.sdkOptions?.capture?.captureSettings?.mode ?? "manual",
              imageFileType,
            },
          ],
          {
            issuingCountry: documentIssuedCountry,
            totalAttempts: 0,
          },
        );

        if (!sdkResult) {
          logAnalyticsEventInfo(analyticsEvents.DD_EMPTY_RESULT, {
            side: _currentSide,
            result: _result,
            type: selectedDocumentType,
            sendType: selectedSendDocumentType,
          });

          await documentDetector.close();

          return;
        }

        // TODO: review if this is necessary
        if (!sdkResult.isCaptureValid) {
          logAnalyticsEventInfo(analyticsEvents.DD_CAPTURE_INVALID, {
            side: _currentSide,
            result: _result,
            type: selectedDocumentType,
            sendType: selectedSendDocumentType,
          });
          reset();
          return;
        }

        await documentDetector.close();

        documentStore.setDetectedDocumentType(sdkResult?.detectedDocument?.type ?? selectedDocumentType);

        if (isInternationalFlow) {
          handleSdkResultInternational(sdkResult, options);
        } else {
          handleSdkResultNational(sdkResult, options);
        }
      } else {
        Logger.error("DD capture", {
          initialized: documentDetector?.getIsInitialized(),
        });
        logAnalyticsEventInfo(analyticsEvents.DD_ERROR, {
          message: "DD capture called without container node or not initialized",
          initialized: documentDetector?.getIsInitialized(),
        });
        options.onError();
      }
    } catch (error) {
      Logger.error("DD capture", error);
      logAnalyticsEventInfo(analyticsEvents.DD_ERROR, {
        error: JSON.stringify(error, Object.getOwnPropertyNames(error)).replace(/[^a-z0-9 -,_.?!:{}/]/gi, ""),
      });
      options.onError();
    }
  };

  const isOnTemplate = () => !!ddStep;

  const changeDocumentSide = async (options) => {
    Logger.console("DD changeDocumentSide", {
      options,
      _result,
      _currentSide,
    });
    logAnalyticsEventInfo(analyticsEvents.DD_INFO, {
      result: _result,
      currentSide: _currentSide,
      step: "changeDocumentSide",
      options,
    });

    await documentDetector.close();

    if (!!options?.newSide) _currentSide = options.newSide;
  };

  const getResults = (side = undefined) => _result[side ?? _currentSide];

  const getPreview = (side = "", ignoreBlob = false) => {
    let result;
    if (!side) {
      result = getResults(_currentSide);
      if (result?.both) {
        result = result.both;
      }
    } else if (side && !!_result?.[side]) {
      result = _result[side];
    } else {
      return undefined;
    }

    if (!result) return "";

    const { pdfPreview } = isPdfPreview();

    if (result.image?.blob?.type?.startsWith("image/" || (result.image?.blob && pdfPreview)) && !ignoreBlob)
      return blobToImage(result.image?.blob);

    return result.image?.url;
  };

  const getInternationalPreview = (side = "") => {
    if (side === documentSides.front) {
      return _result[documentSides.front]?.imageUrl ?? null;
    }

    if (side === documentSides.back) {
      return _result[documentSides.back]?.imageUrl ?? null;
    }

    if (side === documentSides.both) {
      return _result[documentSides.both]?.imageUrl ?? null;
    }
  };

  const getResultKey = (side) => {
    logAnalyticsEventInfo(analyticsEvents.DD_INFO, {
      side,
      result: _result,
      currentSide: _currentSide,
      step: "getResultKey",
    });

    let result;

    if (!side) {
      result = getResults();
    } else if (side && !!_result?.[side]) {
      result = _result[side];
    } else {
      return undefined;
    }

    if (!result) return;

    if (isInternationalFlow) {
      return result?.imageKey;
    }

    return result.image?.storageInfo?.key;
  };

  const getResultBucket = (side) => {
    let result;

    if (!side) {
      result = getResults();
    } else if (side && !!_result?.[side]) {
      result = _result[side];
    } else {
      return undefined;
    }

    if (isInternationalFlow) {
      return result?.imageBucket;
    }

    return result?.image?.storageInfo?.bucket;
  };
  const getCurrentSide = () => _currentSide;

  const getDocumentType = () => {
    let resultSide = _currentSide;
    if (!!_result?.[documentSides.front]) {
      resultSide = documentSides.front;
    } else if (!!_result?.[documentSides.back]) {
      resultSide = documentSides.back;
    } else if (!!_result?.[documentSides.both]) {
      resultSide = documentSides.both;
    }

    return _result?.[resultSide]?.detectedDocument?.type?.toUpperCase();
  };

  const isBothSideCapture = () => {
    // Se é fluxo de captura de documentos BR, ou seja, sem etapa de seleção de país ou Brasil como país selecionado
    const isBrazilianFlow = !documentIssuedCountry || !isInternationalFlow;
    // Documentos que sempre devem enviar apenas uma imagem, pois tem apenas um lado
    const onlyOneSide = ["PASSPORT", "CRLV"].includes(selectedDocumentType.toUpperCase());
    // Documentos que podem ter dois lados, mas que podem ser enviados de forma aberta (ambos lados no mesmo arquivo)
    const supportForBothSides = ["RG", "CNH"].includes(selectedDocumentType.toUpperCase());
    // Documentos que sempre tem frente e verso e não podem ser enviados juntos em apenas um arquivo/imagem (condição do SDK)
    const notHaveBothSideSupport = ["RNE", "RNM", "CTPS"].includes(selectedDocumentType.toUpperCase());
    // Se foi selecionado fluxo de upload de PDF
    const isUploadPdf = selectedDocumentFileDD === "uploadPdf";

    // Nunca habilitar o envio/captura de apenas uma imagem/arquivo quando o documento selecionado for RNE, RNM ou CTPS, pois o SDK não permite
    // Essa verificação não deveria estar no SDK, futuramente quando isso for revisto, essa condicional pode ser removida
    // Essa condicional deve ser prioritária sempre
    if (notHaveBothSideSupport) {
      return false;
    }

    // Envia apenas uma image/arquivo se for marcado que documento não tem frente e verso
    if (isDoubleSided === false) {
      return true;
    }
    // Envia apenas uma image/arquivo se documento selecionado tem apenas um lado
    if (onlyOneSide) {
      return true;
    }
    // Envia apenas uma image/arquivo se for upload de PDF e o documento for internacional (não brasileiro)
    // Essa condicional existe pois no internacional valida as duas imagens juntas e não existe preview de dois PDFs
    if (isUploadPdf && !isBrazilianFlow) {
      return true;
    }
    // Envia apenas uma image/arquivo se for upload de PDF e o documento selecionado deve ser enviado com ambos os lados
    // Pensado para documentos escaneados de forma aberta ou documentos digitais, ambos geralmente são PDFs
    if (isUploadPdf && supportForBothSides) {
      return true;
    }
    return false;
  };

  const isPdfPreview = () => {
    const pdfPreview = selectedDocumentFileDD === "uploadPdf";

    const showPdfPreview = ddStep.previewPDF !== false;

    return {
      pdfPreview,
      showPdfPreview,
    };
  };

  const resetResult = () => {
    logAnalyticsEventInfo(analyticsEvents.DD_INFO, {
      result: _result,
      currentSide: _currentSide,
      step: "resetResult",
    });

    _result = {};
  };

  return {
    isOnTemplate,
    isBothSideCapture,
    isPdfPreview,
    initialize,
    reset,
    captureHandler,
    changeDocumentSide,
    getResults,
    getResultKey,
    getResultBucket,
    getPreview,
    getInternationalPreview,
    getCurrentSide,
    getDocumentType,
    resetResult,
    setCurrentSideToFront,
  };
}
