import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import Card from "@components/Card";
import Button from "@components/Button";
import { useAnalytics } from "@contexts/Analytics";
import useEvents from "@hooks/useEvents";
import store from "@store/index";
import { Logger } from "@utils/logging";
import { parseStep } from "@utils/onboarding";
import { Props as PreviewProps } from "./DDPreview";
import useDocumentDetector, { documentSides } from "./hooks/useDocumentDetector";
import { mapInternationalTextFields } from "./utils";
import { illustrations } from "@utils/illustrations";
import { defaultOnboardingIllustrationsAlternativeText } from "@utils/accessibility";

const I18N_BASE_PATH = "src.pages.onboarding.steps.allSteps.uniqueSteps.dd";

interface Props {
  name: string;
  heading?: string;
  subheading?: string;
  preview?: boolean;
  previewPDF?: boolean;
  previewButton?: string;
  previewHeading?: string;
  previewRetryText?: string;
  previewSubheading?: string;

  errors?: {
    ddOpeningError?: {
      longDescription: string;
      description: string;
      text: string;
    };
  };
}

const DD = (props: Props) => {
  const [error, setError] = useState(false);
  const { t } = useTranslation();
  const {
    logAnalyticsStepInfo,
    analyticsSteps,
    logAnalyticsEventInfo,
    analyticsEvents,
    logAnalyticsActionInfo,
    analyticsActions,
  } = useAnalytics();
  const { emitEvent } = useEvents();
  const documentDetector = useDocumentDetector();

  const { start: startLoader, stop: stopLoader } = store.ui.loading;
  const { setDocumentUrlBack, setDocumentUrlFront, documentIssuedCountry, isDoubleSided, textFieldsData } =
    store.variables.document;
  const { goForward, goBackward, index, addStep, removeStep, haveStep } = store.navigation;

  const [disableGoForward, setDisableGoForward] = useState(true);

  const nationalGoForward = async () => {
    const side = documentDetector.getCurrentSide();
    let frontPreview, backPreview, bothPreview;

    if (side === documentSides.front) {
      frontPreview = documentDetector.getPreview(documentSides.front, true);

      if (frontPreview) {
        setDocumentUrlFront(frontPreview);
        if (props.preview) {
          setDisableGoForward(false);
          goForward();
        } else {
          await documentDetector.changeDocumentSide({
            newSide: documentSides.back,
          });
          ddStepCapture();
        }
      } else {
        ddStepCapture();
      }
    }

    if (side === documentSides.back) {
      backPreview = documentDetector.getPreview(documentSides.back, true);

      if (backPreview) {
        setDocumentUrlBack(backPreview);
        if (props.preview) {
          setDisableGoForward(false);
          goForward();
        } else {
          await documentDetector.reset();
          startLoader({ heading: t("general.message.loader.processingDocument", "Processando documento...") });
          await emitEvent({
            code: "DD_CAPTURE_FINISH",
          });
          stopLoader();
          setDisableGoForward(false);
          goForward();
        }
      } else {
        ddStepCapture();
      }
    }

    if (side === documentSides.both) {
      bothPreview = documentDetector.getPreview(documentSides.both, true);

      if (bothPreview) {
        setDocumentUrlFront(bothPreview);
        if (props.preview) {
          setDisableGoForward(false);
          goForward();
        } else {
          await documentDetector.reset();
          startLoader({ heading: t("general.message.loader.processingDocument", "Processando documento...") });
          await emitEvent({
            code: "DD_CAPTURE_FINISH",
          });
          stopLoader();
          setDisableGoForward(false);
          goForward();
        }
      } else {
        ddStepCapture();
      }
    }
  };

  const internationalGoForward = async () => {
    const frontPreview = documentDetector.getInternationalPreview(documentSides.front);
    const backPreview = documentDetector.getInternationalPreview(documentSides.back);
    const bothPreview = documentDetector.getInternationalPreview(documentSides.both);

    // Validação para documentos doubleSided
    if (isDoubleSided) {
      if (frontPreview && backPreview) {
        setDocumentUrlFront(frontPreview);
        setDocumentUrlBack(backPreview);
        if (props.preview) {
          if (textFieldsData) {
            store.variables.updateVariables(mapInternationalTextFields(textFieldsData));
          }
          setDisableGoForward(false);
          goForward();
          return;
        }
      } else {
        ddStepCapture();
        return;
      }
    } else {
      // Validação para documentos com captura de ambos os lados
      if (bothPreview) {
        setDocumentUrlFront(bothPreview);
        startLoader({ heading: t("general.message.loader.processingDocument", "Processando documento...") });

        if (textFieldsData) {
          store.variables.updateVariables(mapInternationalTextFields(textFieldsData));
        } else {
          await emitEvent({ code: "DD_CAPTURE_FINISH" });
        }
        stopLoader();
        goForward();
        return;
      } else {
        ddStepCapture();
        return;
      }
    }

    // Lógica de fallback para captura de ambos os lados sem preview
    if (!documentDetector.isBothSideCapture()) {
      await documentDetector.reset();
      goForward();
    }

    // Lógica quando há pré-visualização
    if (props.preview) {
      goForward();
    } else {
      ddStepCapture();
    }
  };

  const customGoForward = async () => {
    try {
      logAnalyticsEventInfo(analyticsEvents.DD_GO_FORWARD, {});

      if (documentIssuedCountry !== "BR") {
        await internationalGoForward();
      } else {
        await nationalGoForward();
      }
    } catch (error) {
      logAnalyticsEventInfo(analyticsEvents.DD_ERROR, {
        message: "DD CustomGoForward Error",
        details: JSON.stringify(error, Object.getOwnPropertyNames(error)),
      });
    }
  };

  const customGoBackward = async () => {
    Logger.console("DDStep customGoBackward");
    const currentSide = documentDetector.getCurrentSide();

    if (documentIssuedCountry !== "BR" && currentSide === documentSides.back) {
      documentDetector.changeDocumentSide({
        newSide: documentSides.front,
      });
      ddStepCapture();
      return;
    }

    if (props.preview) {
      if (currentSide === documentSides.front || currentSide === documentSides.both) {
        // keep side and results but close sdk
        await documentDetector.reset();
        documentDetector.changeDocumentSide({
          newSide: documentSides.front,
        });
        goBackward();
      } else if (currentSide === documentSides.back) {
        // keep back results and change side to front
        await documentDetector.changeDocumentSide({
          newSide: documentSides.front,
        });
        goForward();
      }
    } else {
      // no preview

      if (currentSide === documentSides.front || currentSide === documentSides.both) {
        // keep side and results but close sdk

        await documentDetector.changeDocumentSide({
          newSide: documentSides.front,
        });
        // await documentDetector.reset();
        goBackward();
      } else {
        // keep current results and change side to front
        await documentDetector.changeDocumentSide({
          newSide: documentSides.front,
        });
        ddStepCapture();
      }
    }
  };

  const ddStepCapture = () => {
    setError(false);
    Logger.console("DDStep capture");
    startLoader({
      heading: t("general.message.loader.initializingDocumentDetector", "Iniciando detector de documentos..."),
    });
    documentDetector.captureHandler({
      onGetResults: async (side: string, result: any) => {
        logAnalyticsEventInfo(analyticsEvents.DD_INFO, {
          side,
          result,
          step: "onGetResults",
        });
        if (documentIssuedCountry === "BR") {
          customGoForward();
        } else {
          await internationalOnGetResults(side, result);
        }
      },
      onInitialize: () => {
        stopLoader();
      },
      onCaptureStarted: (type: string) => {
        logAnalyticsActionInfo(analyticsActions.DD_CAPTURE_START, { type }, props.name);
      },
      onCaptureInvalid: (type: string) => {
        logAnalyticsActionInfo(analyticsActions.DD_CAPTURE_FAILED, { type }, props.name);
      },
      onError: () => {
        stopLoader();
        setError(true);
      },
    });
  };

  const internationalOnGetResults = async (side: string, result: any) => {
    let imagesValid;

    if (result.front?.imageUrl && result.back?.imageUrl) {
      imagesValid = result.back.valid;
    } else if (result.both?.imageUrl) {
      imagesValid = result.both.valid;
    }

    if (!imagesValid) {
      await documentDetector.changeDocumentSide({
        newSide: documentSides.front,
      });
      setDocumentUrlFront("");
      setDocumentUrlBack("");
      documentDetector.resetResult();
      ddStepCapture();
      return;
    }

    customGoForward();
  };

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_DD);
  }, [logAnalyticsStepInfo]);

  useEffect(() => {
    const { showPdfPreview, pdfPreview } = documentDetector.isPdfPreview();

    if (pdfPreview && !showPdfPreview) {
      removeStep("DD_PREVIEW");
    } else {
      const stepIndex = parseStep(props.name)[1];

      if (props.preview && !haveStep("DD_PREVIEW")) {
        addStep(
          {
            name: `DD_PREVIEW-${stepIndex}`,
            props: {
              name: `DD_PREVIEW-${stepIndex}`,
              button: props.previewButton,
              heading: props.previewHeading,
              retryText: props.previewRetryText,
              subheading: props.previewSubheading,
            } as PreviewProps,
          },
          index + 1,
        );
      }
    }

    ddStepCapture();

    return () => {
      if (process.env.REACT_APP_ENV === "test") {
        // SDK needs to reset to recall webcam stream when testing with cypress
        documentDetector.reset();
      }
    };
  }, []);

  return (
    <Card>
      <Card.Body center>
        {error && (
          <Card.Text
            className="mx-10"
            text={
              props.errors?.ddOpeningError?.text ??
              t(
                `${I18N_BASE_PATH}.errors.ddOpeningError`,
                "Ocorreu um erro ao abrir o Detector de Documentos. Por favor, refaça o Onboarding. Caso o problema persistir, entre em contato com o suporte.",
              )
            }
          />
        )}
        {!error && (
          <>
            <Card.Illustration
              alt={t(defaultOnboardingIllustrationsAlternativeText?.[illustrations.ONBOARDING])}
              src={illustrations.PDF_DOCUMENT}
            />
            <Card.Heading text={t(`${I18N_BASE_PATH}.description`, "Document Capture")} />
            <Card.Subheading text={t(`${I18N_BASE_PATH}.subheading`)} />
            <Button className={"text-primary"} type={"primary"} onClick={ddStepCapture}>
              {t(`${I18N_BASE_PATH}.start`, "Start")}
            </Button>
          </>
        )}
      </Card.Body>
      <Card.NavBar disabledGoForward={disableGoForward} goBackward={customGoBackward} goForward={customGoForward} />
    </Card>
  );
};

export default observer(DD);
